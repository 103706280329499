import React, { useContext } from 'react';
import {
  Modal,
  Portal,
  ProgressBar,
} from 'react-native-paper';
import { NativeBaseProvider, View } from 'native-base';
import { useQuery } from '@apollo/client';
import { ModalContext } from '../../context/ModalContext';
import { FindOneEvent } from '../../gql/event/query';

function Picture() {
  const {
    displayPictureModal,
    togglePictureModal,
  } = useContext(ModalContext);

  const { data, loading, error } = useQuery(
    FindOneEvent,
    {
      variables: {
        id: Number(displayPictureModal?.eventId),
      },
    },
  );

  if (!displayPictureModal.display) return null;

  const base64file = displayPictureModal.isInfoMedia
    ? data?.findOneEvent?.infoMedia
    : data?.findOneEvent?.media;

  return (
    <Modal
      visible={displayPictureModal.display}
      onDismiss={togglePictureModal}
      dismissableBackButton
      style={{
        elevation: 0,
        borderColor: 'transparent',
        boxShadow: 0,
      }}
    >
      <View style={{ boxShadow: 0 }}>
        {loading ? <ProgressBar />
          : (
            <img
              alt="eventImage"
              style={{
                backgroundColor: 'transparent',
                verticalAlign: 'center',
                maxWidth: '100%',
                elevation: 0,
              }}
              src={base64file}
            />
          )}
      </View>
    </Modal>
  );
}

export default function PictureModal() {
  return (
    <Portal style={{ boxShadow: 0 }}>
      <NativeBaseProvider style={{ boxShadow: 0 }}>
        <Picture />
      </NativeBaseProvider>
    </Portal>
  );
}
