// import { StyleSheet } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { DefaultTheme } from 'react-native-paper';

export const theme = {
  ...DefaultTheme,
  roundness: 2,
  dark: true,
  mode: 'exact',
  colors: {
    ...DefaultTheme.colors,
    primary: '#3498db',
    accent: '#f1c40f',
    warning: {
      light: '#ffb74d',
      main: '#ffa726',
      dark: '#f57c00',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
    },
    info: {
      light: '#4fc3f7',
      main: '#29b6f6',
      dark: '#0288d1',
    },
    success: {
      light: '#81c784',
      main: '#66bb6a',
      dark: '#388e3c',
    },
  },
};

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  highZIndex: {
    zIndex: 1000,
  },
  mainTitle: {
    textAlign: 'center',
    marginTop: 30,
    fontSize: 30,
    fontWeight: 'bold',
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 15,
    marginTop: 30,
    color: 'grey',
  },
  middle: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  left: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  right: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  error: {
    color: '#ff0000',
  },
  text2: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 5,
  },
  signupText: {
    fontWeight: 'bold',
  },
  forgotPassword: {
    fontSize: '10',
    fontStyle: 'italic',
  },
  field: {
    marginTop: 30,
  },
  whiteBackground: {
    backgroundColor: 'white',
  },
  input: {
    marginTop: 10,
    marginRight: 5,
  },
  buttonStyle: {
    marginTop: 30,
    marginLeft: 15,
    marginRight: 15,
  },
  buttonStyleX: {
    marginTop: 12,
    marginLeft: 15,
    marginRight: 15,
  },
  buttonDesign: {
    backgroundColor: 'rgba(28, 28, 30, 0.68)',
  },
  lineStyle: {
    flexDirection: 'row',
    marginTop: 30,
    marginLeft: 15,
    marginRight: 15,
    alignItems: 'center',
  },
  imageStyle: {
    width: 80,
    height: 80,
    marginLeft: 20,
  },
  boxStyle: {
    flexDirection: 'row',
    marginTop: 30,
    marginLeft: 15,
    marginRight: 15,
    justifyContent: 'space-around',
  },
  logoStyle: {
    '@media (max-width: 800px)': {
      width: '50%',
    },
    padding: 20,
  },
  responsiveImage: {
    '@media (min-width: 800px)': {
      height: '200px',
      width: '200px',
      alignItems: 'center',
    },
    '@media (max-width: 800px)': {
      aspectRatio: 1 / 1,
      width: '100%',
      height: undefined,
    },
  },
  cardTerminated: {
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  cardDisabled: {
    color: 'grey',
  },
});

export { ids, styles };
