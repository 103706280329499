import { useAssets } from 'expo-asset';
import Constants from 'expo-constants';
import * as SplashScreen from 'expo-splash-screen';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Animated, StyleSheet, TouchableOpacity, View,
} from 'react-native';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import {
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import { ApolloProvider, useQuery } from '@apollo/client';
import {
  Avatar, Badge, PaperProvider,
} from 'react-native-paper';
import { ToastProvider } from 'react-native-toast-notifications';
import client from './lib/apollo-client';
import { AuthContext, AuthProvider } from './context/AuthContext';
import DashboardScreen from './screens/Dashboard/DashboardScreen';
import ManageUsersScreen from './screens/ManageUsers/ManageUsersScreen';
import LoginScreen from './screens/Authentication/LoginScreen';
import { ROLE_NAMES, SCREEN_NAMES } from './utils/constant';
import InviteScreen from './screens/Authentication/InviteScreen';
import ProfileScreen from './screens/Profile/ProfileScreen';
import SignUpScreen from './screens/Authentication/SignUpScreen';
import ForgotPasswordScreen from './screens/Authentication/ForgotPasswordScreen';
import { theme } from './screens/Common.style';
import { ModalProvider } from './context/ModalContext';
import ManageAnnouncementsScreen from './screens/ManageAnnouncements/ManageAnnouncementsScreen';
import ManageEventsScreen from './screens/ManageEvents/ManageEventsScreen';
import { FindOneUserQuery } from './gql/user/query';
import EventScreen from './screens/Event/EventScreen';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

function CustomDrawerContent(props) {
  const authContext = useContext(AuthContext);
  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
      <DrawerItem label="Se déconnecter" onPress={() => authContext.logout()} />
    </DrawerContentScrollView>
  );
}

function DrawerScreen() {
  const authContext = useContext(AuthContext);
  const {
    data: oneUserQuery,
    loading: oneUserQueryLoading,
    error: oneUserQueryError,
    refetch,
  } = useQuery(FindOneUserQuery, {
    variables: {
      email: authContext.authState.email,
    },
  });
  const user = oneUserQuery?.findOneUser;
  const userInitialLabel = `${authContext?.authState?.username?.split(' ')[0][0]}${
    authContext?.authState?.username?.split(' ')[1][0]
  }`;

  const navigation = useNavigation();

  return (
    <Drawer.Navigator
      initialRouteName={SCREEN_NAMES.DASHBOARD}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      screenOptions={{
        headerRight: (props) => (
          <TouchableOpacity onPress={() => navigation.navigate(SCREEN_NAMES.PROFILE)}>
            { user?.status !== 'COMPETITOR' && (
            <Badge style={{
              position: 'absolute', top: 5, right: 5, zIndex: 2,
            }}
            />
            )}
            <Avatar.Text {...props} size={36} label={userInitialLabel} style={{ margin: '10px' }} />
          </TouchableOpacity>
        ),
        drawerPosition: 'left',
      }}
    >
      <Drawer.Screen name={SCREEN_NAMES.DASHBOARD} component={DashboardScreen} />
      <Drawer.Screen name={SCREEN_NAMES.PROFILE} component={ProfileScreen} />
      <Drawer.Screen
        name={SCREEN_NAMES.EVENT}
        component={EventScreen}
        options={{ drawerItemStyle: { display: 'none' } }}
      />
      {[ROLE_NAMES.COACH, ROLE_NAMES.SUPERADMIN].includes(authContext?.authState?.role) && (
        <>
          { authContext?.authState?.role === ROLE_NAMES.SUPERADMIN
            && <Drawer.Screen name={SCREEN_NAMES.USERMANAGEMENT} component={ManageUsersScreen} />}
          <Drawer.Screen
            name={SCREEN_NAMES.ANNOUNCEMENTMANAGEMENT}
            component={ManageAnnouncementsScreen}
          />
          <Drawer.Screen
            name={SCREEN_NAMES.EVENTMANAGEMENT}
            component={ManageEventsScreen}
          />
          <Drawer.Screen
            options={{
              drawerItemStyle: { display: 'none' },
            }}
            name={SCREEN_NAMES.INVITE}
            component={InviteScreen}
          />
        </>
      )}
    </Drawer.Navigator>
  );
}

// Instruct SplashScreen not to hide yet, we want to do this manually
SplashScreen.preventAutoHideAsync().catch(() => {
  /* reloading the app might trigger some race conditions, ignore them */
});

function App() {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadJWT();
  }, [authContext.loadJWT]);

  return authContext?.authState?.authenticated === true ? (
    <DrawerScreen />
  ) : (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Screen name="Signup" component={SignUpScreen} />
      <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />
    </Stack.Navigator>
  );
}

export default function () {
  return (
    <AnimatedAppLoader>
      <ApolloProvider client={client}>
        <AuthProvider>
          <ToastProvider>
            <ModalProvider>
              <NavigationContainer linking>
                <PaperProvider theme={theme}>
                  <App />
                </PaperProvider>
              </NavigationContainer>
            </ModalProvider>
          </ToastProvider>
        </AuthProvider>
      </ApolloProvider>
    </AnimatedAppLoader>
  );
}

function AnimatedAppLoader({ children }) {
  const [isSplashReady, setSplashReady] = useState(false);
  const [assets, error] = useAssets([require('./assets/logo.png')]);

  useEffect(() => {
    async function prepare() {
      setSplashReady(true);
    }

    if (assets) prepare();
  }, [assets]);

  if (!isSplashReady) {
    return null;
  }

  return <AnimatedSplashScreen image={assets[0]}>{children}</AnimatedSplashScreen>;
}

function AnimatedSplashScreen({ children, image }) {
  const animation = useMemo(() => new Animated.Value(1), []);
  const [isAppReady, setAppReady] = useState(false);
  const [isSplashAnimationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    if (isAppReady) {
      Animated.timing(animation, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: true,
      }).start(() => setAnimationComplete(true));
    }
  }, [isAppReady]);

  const onImageLoaded = useCallback(async () => {
    try {
      await SplashScreen.hideAsync();
      // Load stuff
      await Promise.all([]);
    } catch (e) {
      // handle errors
    } finally {
      setAppReady(true);
    }
  }, []);

  return (
    <View style={{ flex: 1 }}>
      {isAppReady && children}
      {!isSplashAnimationComplete && (
        <Animated.View
          pointerEvents="none"
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: Constants.manifest.splash.backgroundColor,
              opacity: animation,
            },
          ]}
        >
          <Animated.Image
            style={{
              width: '100%',
              height: '100%',
              resizeMode: Constants.manifest.splash.resizeMode || 'contain',
              transform: [
                {
                  scale: animation,
                },
              ],
            }}
            source={image}
            onLoadEnd={onImageLoaded}
            fadeDuration={0}
          />
        </Animated.View>
      )}
    </View>
  );
}
