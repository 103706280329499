import React from 'react-native';
import {
  NativeBaseProvider, ScrollView,
} from 'native-base';
import {
  Button, Dialog, IconButton, List, Portal,
  ProgressBar,
} from 'react-native-paper';
import { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ModalContext } from '../../context/ModalContext';
import { FindOneEvent } from '../../gql/event/query';
import { ConfirmEvent, LeaveEvent } from '../../gql/event/mutation';
import { AuthContext } from '../../context/AuthContext';

function PlayersOnEvent({ eventId }) {
  const {
    displayPlayersOnEventModal,
    togglePlayersOnEventModal,
  } = useContext(ModalContext);

  const { isSuperAdmin } = useContext(AuthContext);

  const { data, loading, error } = useQuery(
    FindOneEvent,
    { variables: { id: eventId } },
  );
  const isAdd = displayPlayersOnEventModal.action === 'ADD';

  const [confirmPlayerOnEvent] = useMutation(ConfirmEvent);
  const [deletePlayerOnEvent] = useMutation(LeaveEvent);

  return loading ? <ProgressBar />
    : (
      <Dialog
        visible={displayPlayersOnEventModal}
        dismissable
        onDismiss={togglePlayersOnEventModal}
      >
        <Dialog.Title>
          {`${data?.findOneEvent?.name}: ${isAdd ? 'liste d\'attente' : 'inscrits'}`}
        </Dialog.Title>
        <Dialog.ScrollArea>
          <ScrollView style={{ maxHeight: '80vh' }}>
            {data?.findOneEvent?.players
              ?.filter((p) => (isAdd ? !p.isConfirmed : p.isConfirmed)).map((p) => (
                <List.Item
                  key={p.player.id}
                  title={`${p.player.firstName} ${p.player.lastName}`}
                  right={() => (
                    <>
                      <IconButton
                        icon={isAdd ? 'plus' : 'minus'}
                        onPress={() => {
                          confirmPlayerOnEvent(
                            {
                              variables: { playerId: p.player.id, eventId, isConfirmed: isAdd },
                              refetchQueries: [
                                FindOneEvent,
                                'FindOneEvent',
                              ],
                            },
                          );
                        }}
                      />
                      { isSuperAdmin && (
                      <IconButton
                        icon="delete"
                        onPress={() => deletePlayerOnEvent({
                          variables: { eventId, userId: p.player.id },
                          refetchQueries: [
                            FindOneEvent,
                            'FindOneEvent',
                          ],
                        })}
                      />
                      )}
                    </>
                  )}
                />
              ))}
          </ScrollView>
        </Dialog.ScrollArea>
        <Dialog.Actions>
          <Button onPress={togglePlayersOnEventModal}>
            Fermer
          </Button>
        </Dialog.Actions>
      </Dialog>
    );
}

export default function PlayersOnEventModal({ eventId }) {
  return (
    <Portal>
      <NativeBaseProvider>
        <PlayersOnEvent eventId={eventId} />
      </NativeBaseProvider>
    </Portal>
  );
}
