import React from 'react-native';
import {
  NativeBaseProvider, ScrollView,
} from 'native-base';
import {
  ActivityIndicator,
  Avatar,
  Button, Chip, Dialog, Divider, IconButton, List, MD2Colors, Portal, SegmentedButtons,
} from 'react-native-paper';
import { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ModalContext } from '../../context/ModalContext';
import { FindOneEvent } from '../../gql/event/query';
import { EVENT_PARTICIPANT_MODE } from '../../utils/constant';
import { styles } from '../../screens/Common.style';

function EventAchievement({ eventId }) {
  const {
    displayEventAchievementsModal,
    toggleEventAchievementsModal,
  } = useContext(ModalContext);

  const { data, loading, error } = useQuery(
    FindOneEvent,
    { variables: { id: Number(eventId) } },
  );

  const [filters, setFilters] = useState([1, 2, 3]);

  return loading ? <ActivityIndicator animating color={MD2Colors.red800} /> : (
    <Dialog
      visible={displayEventAchievementsModal}
      dismissable
      onDismiss={toggleEventAchievementsModal}
    >
      <Dialog.Title>
        {`Résultats ${data?.findOneEvent?.name}`}
      </Dialog.Title>
      <Dialog.ScrollArea style={{ maxHeight: '80vh' }}>
        <SegmentedButtons
          value={filters}
          multiSelect
          style={{ paddingBottom: '5px' }}
          onValueChange={(e) => setFilters(e)}
          buttons={[
            {
              value: 1,
              label: 'Premiers',
            },
            {
              value: 2,
              label: 'Deuxièmes',
            },
            {
              value: 3,
              label: 'Troisièmes',
            },
          ]}
        />
        <ScrollView>
          {data?.findOneEvent?.players.filter((p) => p.achievements?.length > 0
            && p.achievements.some((achievement) => filters.includes(achievement.rank))).map((p) => (
              <>
                <Divider bold />
                <List.Accordion
                  title={`${p.player.firstName} ${p.player.lastName}`}
                  style={{ backgroundColor: 'transparent' }}
                  expanded
                  right={(props) => (
                    <Avatar.Image
                      {...props}
                      source={{
                        uri: p.player.profile.picture,
                      }}
                    />
                  )}
                >
                  {p.achievements.map((achievement) => (
                    <List.Item
                      right={(props) => (
                        <>
                          <Chip {...props}>{achievement.category}</Chip>
                          <Chip {...props}>
                            {achievement.weightCategory}
                            {' '}
                            kg
                          </Chip>
                          <Chip {...props}>{Object.entries(EVENT_PARTICIPANT_MODE).find((item) => item[0] === achievement.mode)[1]}</Chip>
                          <IconButton
                            {...props}
                            icon={achievement.rank === 1
                              ? 'podium-gold'
                              : achievement.rank === 2 ? 'podium-silver'
                                : 'podium-bronze'}
                          />
                        </>
                      )}
                    />
                  ))}
                </List.Accordion>
              </>
          ))}
        </ScrollView>
      </Dialog.ScrollArea>
      <Dialog.Actions>
        <Button onPress={toggleEventAchievementsModal}>
          Fermer
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export default function EventAchievementModal({ eventId }) {
  return (
    <Portal>
      <NativeBaseProvider>
        <EventAchievement eventId={eventId} />
      </NativeBaseProvider>
    </Portal>
  );
}
