export const dateTimeOptions = {
  weekday: 'long',
  day: 'numeric',
  year: 'numeric',
  month: 'short',
};

export const SCREEN_NAMES = {
  LOGIN: 'Login',
  DASHBOARD: 'Tableau de bord',
  USERMANAGEMENT: 'Gérer les utilisateurs',
  ANNOUNCEMENTMANAGEMENT: 'Gérer les annonces',
  EVENTMANAGEMENT: 'Gérer les évènements',
  INVITE: 'Inviter un utilisateur',
  PROFILE: 'Profil',
  EVENT: 'Evènement',
};

export const ROLE_NAMES = {
  USER: 'USER',
  COACH: 'COACH',
  SUPERADMIN: 'SUPERADMIN',
};

export const EVENT_PARTICIPANT_MODE = {
  INDIVIDUAL: 'Individuel',
  TEAM: 'Par équipe',
};

export const STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const GENDER_TYPE = {
  MAN: 'Masculin',
  WOMAN: 'Féminin',
};

export const LEGAL_GUARDIAN_TYPE = {
  FATHER: 'Père',
  MOTHER: 'Mère',
  OTHER: 'Autre',
};

export const EVENT_TYPE = {
  COMPETITION: 'Competition',
  STAGE: 'Stage',
};

export const EVENT_STATUS = {
  SCHEDULED: 'Planifié',
  OPEN: 'Ouvert',
  LIVE: 'Live',
  CLOSED: 'Cloturé',
};

export const EVENT_LEVEL = {
  INTERNATIONAL: 'International',
  NATIONAL: 'National',
  REGIONAL: 'Regional',
};

export const GRADE = {
  WHITE: 'Blanche',
  YELLOW: 'Jaune',
  ORANGE: 'Orange',
  GREEN: 'Verte',
  BLUE: 'Bleue',
  BROWN: 'Marron',
  BLACK: 'Noire',
};

export const AGE_CATEGORY = {
  miniPoussin: {
    minYear: 4,
    maxYear: 5,
    name: 'Mini poussin',
    weights: {
      woman: [20, 25, 30, 35],
      man: [20, 25, 30, 35, 40],
    },
  },
  poussin: {
    minYear: 6,
    maxYear: 7,
    name: 'Poussin',
    weights: {
      woman: [20, 25, 30, 35, 40],
      man: [20, 25, 30, 35, 40, 45],
    },
  },
  pupille: {
    minYear: 8,
    maxYear: 9,
    name: 'Pupille',
    weights: {
      woman: [25, 30, 35, 40, 45],
      man: [25, 30, 35, 40, 45, 50],
    },
  },
  benjamin: {
    minYear: 10,
    maxYear: 11,
    name: 'Benjamin',
    weights: {
      woman: [30, 35, 40, 45, 50],
      man: [30, 35, 40, 45, 50, 55],
    },
  },
  minime: {
    minYear: 12,
    maxYear: 13,
    name: 'Minime',
    weights: {
      woman: [35, 40, 45, 50, 55],
      man: [35, 40, 45, 50, 55, 60, 65],
    },
  },
  cadet: {
    minYear: 14,
    maxYear: 15,
    name: 'Cadet',
    weights: {
      woman: [47, 54, 61],
      man: [52, 57, 63, 70],
    },
  },
  junior: {
    minYear: 16,
    maxYear: 17,
    name: 'Junior',
    weights: {
      woman: [48, 53, 59, 66],
      man: [55, 61, 68, 76],
    },
  },
  senior: {
    minYear: 18,
    maxYear: 99,
    name: 'Senior',
    weights: {
      woman: [50, 55, 61, 68],
      man: [60, 67, 75, 84],
    },
  },
  espoir: {
    minYear: 18,
    maxYear: 20,
    name: 'Espoir',
    weights: {
      woman: [50, 55, 61, 68],
      man: [60, 67, 75, 84],
    },
  },
  veteran1: {
    minYear: 35,
    maxYear: 45,
    name: 'Vétéran 1',
    weights: {
      woman: [55, 61, 68],
      man: [67, 75, 84],
    },
  },
  veteran2: {
    minYear: 36,
    maxYear: 55,
    name: 'Vétéran 2',
    weights: {
      woman: [55, 61, 68],
      man: [67, 75, 84],
    },
  },
  veteran3: {
    minYear: 56,
    maxYear: 65,
    name: 'Vétéran 3',
    weights: {
      woman: [55, 61, 68],
      man: [67, 75, 84],
    },
  },
  veteran4: {
    minYear: 66,
    maxYear: 99,
    name: 'Vétéran 4',
    weights: {
      woman: [55, 61, 68],
      man: [67, 75, 84],
    },
  },
};
