import { gql } from '@apollo/client';

export const CreateAchievement = gql`
mutation CreateAchievement($eventId: Int!, $rank: Int!, $category: String!, $weightCategory: String!, $mode: String!, $userId: Int) {
  createAchievement(eventId: $eventId, rank: $rank, category: $category, weightCategory: $weightCategory, mode: $mode, userId: $userId) {
    id
  }
}
`;

export const DeleteAchievement = gql`
mutation DeleteAchievement($id: Int!) {
  deleteAchievement(id: $id) {
    message
    status
  }
}
`;
