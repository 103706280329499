import { ErrorMessage, Field, Formik } from 'formik';
import { FormControl, Input } from 'native-base';
import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { Card, Text } from 'react-native-paper';
import { DatePickerInput } from 'react-native-paper-dates';
import SelectBox from 'react-native-multi-selectbox';
import { xorBy } from 'lodash';
import {
  array, date, object, string,
} from 'yup';
import { useMutation } from '@apollo/client';
import { styles } from '../../Common.style';
import { AGE_CATEGORY } from '../../../utils/constant';
import { UpdateEvent } from '../../../gql/event/mutation';
import { FindOneEvent } from '../../../gql/event/query';

function EventInfoTab({ event, disabled = true }) {
  const [updateEvent] = useMutation(UpdateEvent);

  const saveEventCallback = async (data) => {
    if (data && !disabled) {
      await updateEvent({
        variables: {
          id: Number(event.id),
          name: data.name,
          location: data.location,
          startAt: data.startAt,
          registerEndAt: data.registerEndAt,
          endAt: data.endAt,
          categories: data.categories?.map((c) => c.item),
        },
        refetchQueries: [
          FindOneEvent,
          'FindOneEvent',
        ],
      });
    }
  };

  function onMultiChange({ field, form, item }) {
    const newFieldValue = xorBy(field.value, [item], 'id');
    form.setFieldValue('categories', newFieldValue);
    saveEventCallback({ categories: newFieldValue });
  }
  const entriesCategories = Object.entries(AGE_CATEGORY);
  return (
    <ScrollView keyboardShouldPersistTaps="always">
      <Card.Content>
        <Formik
          initialValues={{
            name: event.name,
            location: event.location,
            startAt: event.startAt,
            registerEndAt: event.registerEndAt,
            endAt: event.endAt,
            categories: event.categories?.map((c) => {
              const foundEntry = entriesCategories.find((ag) => ag[1].name === c);
              return ({ id: foundEntry[0], item: foundEntry[1].name });
            }),
          }}
          validateOnChange
          validationSchema={object({
            name: string().required('Champ requis'),
            location: string().required('Champ requis'),
            type: object().required('Champ requis'),
            startAt: date()
              .required('Champ requis')
              .min(new Date(Date.now()), 'Impossible de mettre une date antérieure'),
            registerEndAt: date()
              .typeError('La date limite d\'inscription est requise')
              .required('La date limite d\'inscription est requise')
              .when('endAt', (endAt) => {
                if (endAt) {
                  return date()
                    .max(endAt, 'La date limite d\'inscription doit être avant la date de fin')
                    .required('La date limite d\'inscription est requise');
                }
              }),
            endAt: date()
              .typeError('La date de fin est requise')
              .required('La date de fin est requise')
              .when('startAt', (startAt) => {
                if (startAt) {
                  return date()
                    .min(startAt, 'La date de fin doit être après la date de début')
                    .typeError('La date de fin est requise');
                }
              }),
            categories: array().min(1, 'Il faut ajouter au moins une catégorie.'),
            level: object().required('Champ requis'),
          })}
        >
          {({
            handleChange, values, errors,
          }) => (
            <>
              <FormControl style={styles.field}>
                <FormControl.Label>Titre</FormControl.Label>
                <Input
                  isDisabled={disabled}
                  onChangeText={handleChange('name')}
                  onBlur={() => saveEventCallback({ name: values.name })}
                  value={values.name}
                />
              </FormControl>
              <FormControl style={styles.field}>
                <FormControl.Label>Lieu</FormControl.Label>
                <Input
                  isDisabled={disabled}
                  onChangeText={handleChange('location')}
                  onBlur={() => saveEventCallback({ location: values.location })}
                  value={values.location}
                />
              </FormControl>
              <FormControl style={styles.field}>
                <FormControl.Label>Date de début</FormControl.Label>
                <Field
                  id="startAt"
                  name="startAt"
                >
                  {({ field, form }) => (
                    <DatePickerInput
                      disabled={disabled}
                      style={styles.whiteBackground}
                      locale="fr-FR"
                      label="Date de début"
                      value={new Date(field.value ?? Date.now())}
                      onChange={(d) => {
                        d.setHours(d.getHours() + 12);
                        if (d !== new Date(field.value)) form.setFieldValue('startAt', d);
                        if (d < new Date(values.endAt)
                        && d > new Date(Date.now())) saveEventCallback({ startAt: d });
                      }}
                      inputMode="start"
                      mode="flat"
                    />
                  )}
                </Field>
                { !!errors.startAt && <Text style={styles.error}>{errors.startAt}</Text>}
              </FormControl>
              <FormControl style={styles.field}>
                <FormControl.Label>Date limite d'inscription</FormControl.Label>
                <Field
                  id="registerEndAt"
                  name="registerEndAt"
                >
                  {({ field, form }) => (
                    <DatePickerInput
                      disabled={disabled}
                      style={styles.whiteBackground}
                      locale="fr-FR"
                      label="Date limite d'inscription"
                      value={new Date(field.value ?? values.registerEndAt)}
                      onChange={(d) => {
                        d.setHours(d.getHours() + 12);
                        if (d !== new Date(field.value)) form.setFieldValue('registerEndAt', d);
                        if (d < new Date(values.endAt)) saveEventCallback({ registerEndAt: d });
                      }}
                      inputMode="start"
                      mode="flat"
                    />
                  )}
                </Field>
                { !!errors.registerEndAt && <Text style={styles.error}>{errors.registerEndAt}</Text>}
              </FormControl>
              <FormControl style={styles.field}>
                <FormControl.Label>Date de fin</FormControl.Label>
                <Field
                  id="endAt"
                  name="endAt"
                >
                  {({ field, form }) => (
                    <DatePickerInput
                      disabled={disabled}
                      style={styles.whiteBackground}
                      locale="fr-FR"
                      label="Date de fin"
                      value={new Date(field.value ?? Date.now())}
                      onChange={(d) => {
                        d.setHours(d.getHours() + 12);
                        if (d !== new Date(field.value)) form.setFieldValue('endAt', d);
                        if (d > new Date(values.startAt)) saveEventCallback({ endAt: d });
                      }}
                      inputMode="start"
                      mode="flat"
                    />
                  )}
                </Field>
                { !!errors.endAt && <Text style={styles.error}>{errors.endAt}</Text>}
              </FormControl>
              <FormControl style={styles.field}>
                <FormControl.Label>Catégories</FormControl.Label>
                <Field
                  id="categories"
                  name="categories"
                >
                  {({ field, form }) => (
                    <SelectBox
                      label=""
                      containerStyle={{
                        flexBasis: 'fit-content', flexWrap: 'wrap', ...styles.whiteBackground,
                      }}
                      multiOptionContainerStyle={{ flexBasis: 'fit-content', flexWrap: 'wrap' }}
                      options={Object.entries(AGE_CATEGORY).map(
                        (e) => ({ id: e[0], item: e[1].name }),
                      )}
                      selectedValues={field.value}
                      onMultiSelect={(item) => (disabled ? undefined : onMultiChange({ field, form, item }))}
                      onTapClose={(item) => (disabled ? undefined : onMultiChange({ field, form, item }))}
                      isMulti
                    />
                  )}
                </Field>
                <ErrorMessage name="categories" render={(message) => (<Text style={styles.error}>{message}</Text>)} />
              </FormControl>
            </>
          )}
        </Formik>
      </Card.Content>
    </ScrollView>
  );
}

export default EventInfoTab;
