import { AGE_CATEGORY } from "./constant";
import { DateTime } from "luxon";

export const computeCategorie = (date: Date) => {
    const today = DateTime.now();
    const age = Math.trunc(today.diff(DateTime.fromJSDate(date), 'years').years);
    const categories = Object.entries(AGE_CATEGORY).map(c => {if (age >= c[1].minYear && age <= c[1].maxYear) return c[1].name}).filter(c => c!== undefined);
    return categories;
}

export const computeWeightBasedOnCategorie = (weight, gender, category) => {
    const objCategorie = Object.values(AGE_CATEGORY).find(c => c.name === category);
    const weights = objCategorie.weights[gender.toLowerCase()];
    const foundWeight = weights.find(w => weight < w );
    return foundWeight === undefined ? `+${weights[weights.length - 1]}` : `-${foundWeight}`;
}

export const computeAge = (birthDate) => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);

export const fileSizeInBytes = (base64) => {
    const base64String = base64.replaceAll('=', '');
    const bytes = base64String.length * (3 / 4);

    return bytes;
}

export const readableFileSize = (attachmentSize) => {
    const DEFAULT_SIZE = 0;
    const fileSize = attachmentSize ?? DEFAULT_SIZE;
  
    if (!fileSize) {
      return `${DEFAULT_SIZE} kb`;
    }
  
    const sizeInKb = fileSize / 1024;
  
    if (sizeInKb > 1024) {
      return `${(sizeInKb / 1024).toFixed(2)} mb`;
    } else {
      return `${sizeInKb.toFixed(2)} kb`;
    }
  }

  export const getFileType = (base64file) => base64file.split(';')[0].split(':')[1];