import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Box, Image, NativeBaseProvider, ScrollView, useToast, View,
} from 'native-base';
import {
  Avatar, IconButton, Banner, Text,
  ActivityIndicator,
  MD2Colors,
} from 'react-native-paper';
import { useMutation, useQuery } from '@apollo/client';
import { styles, ids } from '../Common.style';
import { FindManyEvents } from '../../gql/event/query';
import {
  EVENT_STATUS,
  ROLE_NAMES,
} from '../../utils/constant';
import { FindManyAnnouncements } from '../../gql/announcement/query';
import { AcknowledgeAnnouncement } from '../../gql/announcement/mutation';
import { AuthContext } from '../../context/AuthContext';
import { computeCategorie } from '../../utils/utils';
import { FindOneUserQuery } from '../../gql/user/query';
import { ModalContext } from '../../context/ModalContext';
import InformationModal from '../../modals/Information/InformationModal';
import PlayersOnEventModal from '../../modals/ManageEvent/DisplayPlayersOnEventModal';
import ManageFiltersModal from '../../modals/ManageFilters/index';
import PictureModal from '../../modals/Picture/PictureModal';
import EventCard from './EventCardView/EventCardView';
import EventAchievementModal from '../../modals/ManageAchievements/DisplayEventAchievementsModal';

function Dashboard() {
  const { authState } = useContext(AuthContext);
  const toast = useToast();
  const refEventId = useRef(undefined);

  const [today, setToday] = useState(new Date());

  const [filterEventStatus, setFilterEventStatus] = useState('SCHEDULED');

  const {
    data: announcementData,
    loading: announcementLoading,
    error: announcementError,
  } = useQuery(
    FindManyAnnouncements,
    {
      variables: {
        endDate: today,
      },
    },
  );
  const [acknownledgeAnnouncement] = useMutation(AcknowledgeAnnouncement);

  const {
    displayInformationModal,
    displayPlayersOnEventModal, togglePlayersOnEventModal,
    displayPictureModal,
    displayFiltersModal, toggleFiltersModal,
    displayEventAchievementsModal,
  } = useContext(ModalContext);
  const {
    data: oneUserQuery,
    loading: oneUserQueryLoading,
    error: oneUserQueryError,
  } = useQuery(FindOneUserQuery, {
    variables: {
      email: authState.email,
    },
  });

  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
  } = useQuery(FindManyEvents, {
    variables: {
      status: filterEventStatus,
    },
  });

  // Computed values
  const user = oneUserQuery?.findOneUser;
  const isSuperAdmin = authState.role === ROLE_NAMES.SUPERADMIN;
  const isAdmin = [ROLE_NAMES.COACH, ROLE_NAMES.SUPERADMIN].includes(authState.role);
  const userCategories = computeCategorie(new Date(user?.profile?.dateOfBirth));

  const handlePlayersOnEvent = (_eventId, action) => {
    if (isAdmin) {
      refEventId.current = Number(_eventId);
      togglePlayersOnEventModal({ action });
    }
  };

  if (announcementError || oneUserQueryError || eventsError) {
    toast.show('Erreur inattendue');
  }

  useEffect(() => {
    toggleFiltersModal({
      display: false,
      filters: [{
        name: 'Etat de l\'évènement',
        multiSelect: false,
        value: filterEventStatus,
        setValue: setFilterEventStatus,
        values: Object.entries(EVENT_STATUS)
          .filter((e) => e[1] !== EVENT_STATUS.OPEN).map((e) => ({ id: e[0], item: e[1] })),
      }],
    });
  }, [filterEventStatus]);

  return (
    <View style={{ maxHeight: '100vh' }}>
      <View style={styles.container}>
        {announcementData?.findManyAnnouncements?.map((a) => (
          <Banner
            visible
            actions={[
              {
                label: 'J\'ai lu',
                onPress: () => {
                  acknownledgeAnnouncement(
                    {
                      variables: { acknowledgeAnnouncementId: a.announcement.id, hasBeenRead: true },
                      refetchQueries: [
                        FindManyAnnouncements, // DocumentNode object parsed with gql
                        'findManyAnnouncements', // Query name
                      ],
                    },

                  );
                },
              },
            ]}
            icon={() => (a.announcement.author.profile.picture
              ? (
                <Avatar.Image
                  size={50}
                  source={{
                    uri: a.announcement.author.profile.picture,
                    width: '200px',
                  }}
                />
              )
              : (
                <Avatar.Text
                  size={50}
                  label={`${a.announcement.author.firstName[0]}${a.announcement.author.lastName[0]}`}
                />
              ))}
          >
            <Text variant="labelMedium">{a.announcement.title}</Text>
            <br />
            <Text variant="labelSmall">{a.announcement.message}</Text>
          </Banner>
        ))}
        <View style={styles.middle}>
          <Box style={styles.logoStyle} dataSet={{ media: ids.logoStyle }}>
            <Image
              style={styles.responsiveImage}
              dataSet={{ media: ids.responsiveImage }}
              roundedTop="lg"
              source={require('../../assets/logo.png')}
              alt="image"
            />
          </Box>
        </View>
        <View style={styles.right}>
          <IconButton icon="sort" onPress={toggleFiltersModal} />
        </View>
        {(announcementLoading || oneUserQueryLoading || eventsLoading)
      && <ActivityIndicator animating color={MD2Colors.red800} />}
        <View>
          {displayFiltersModal.display && <ManageFiltersModal />}
          {displayPictureModal.display && <PictureModal />}
          {displayInformationModal.display && <InformationModal />}
          {displayPlayersOnEventModal.display
        && <PlayersOnEventModal eventId={refEventId.current} /> }
          <ScrollView maxHeight="70vh">
            {eventsData?.findManyEvents?.map((event) => (
              <>
                {displayEventAchievementsModal && <EventAchievementModal eventId={event.id} />}
                <EventCard
                  user={user}
                  event={event}
                  today={today}
                  isSuperAdmin={isSuperAdmin}
                  userCategories={userCategories}
                  handlePlayersOnEvent={handlePlayersOnEvent}
                />
              </>
            ))}
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

export default function () {
  return (
    <NativeBaseProvider>
      <Dashboard />
    </NativeBaseProvider>
  );
}
