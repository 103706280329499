import React from 'react';
import { ErrorMessage, Field } from 'formik';
import {
  FormControl, Input, Text,
} from 'native-base';
import { DatePickerInput } from 'react-native-paper-dates';
import SelectBox from 'react-native-multi-selectbox';
import { xorBy } from 'lodash';
import { IconButton, Card } from 'react-native-paper';
import { launchImageLibrary } from 'react-native-image-picker';
import { addDays } from 'date-fns';
import { useWizard } from 'react-formik-step-wizard';
import { getDocumentAsync } from 'expo-document-picker';
import { styles } from '../../screens/Common.style';
import AutocompleteLocation from '../../components/commons/AutocompleteLocation';
import { AGE_CATEGORY, EVENT_LEVEL, EVENT_TYPE } from '../../utils/constant';

function FormStepOne() {
  return (
    <>
      <FormControl style={styles.field}>
        <FormControl.Label>Nom de l'évènement</FormControl.Label>
        <Field id="name" name="name">
          {({ field, form }) => (
            <Input
              style={styles.whiteBackground}
              value={field.value}
              onChange={(event) => form.setFieldValue('name', event.target.value)}
            />
          )}
        </Field>
        <ErrorMessage name="name" render={(message) => (<Text style={styles.error}>{message}</Text>)} />
      </FormControl>
      <FormControl style={styles.field}>
        <FormControl.Label>Type d'évènement</FormControl.Label>
        <Field id="type" name="type">
          {({ field, form }) => (
            <SelectBox
              label=""
              options={Object.entries(EVENT_TYPE).map((e) => ({ id: e[0], item: e[1] }))}
              value={field.value}
              containerStyle={styles.whiteBackground}
              onChange={(value) => form.setFieldValue('type', value)}
            />
          )}
        </Field>
        <ErrorMessage name="type" render={(message) => (<Text style={styles.error}>{message}</Text>)} />
      </FormControl>
      <FormControl style={styles.field}>
        <FormControl.Label>Lieu</FormControl.Label>
        <Field
          id="location"
          name="location"
        >
          {({ field, form }) => (
            <AutocompleteLocation
              style={styles.highZIndex}
              value={field.value}
              handleChangeText={(value) => form.setFieldValue('location', value)}
              handleSubmit={(value) => form.setFieldValue('location', value)}
            />
          )}
        </Field>
        <ErrorMessage name="location" render={(message) => (<Text style={styles.error}>{message}</Text>)} />
      </FormControl>
      <FormControl style={styles.field}>
        <FormControl.Label>Date de début</FormControl.Label>
        <Field
          id="startAt"
          name="startAt"
        >
          {({ field, form }) => (
            <DatePickerInput
              style={styles.whiteBackground}
              locale="fr-FR"
              label="Date de début"
              value={new Date(field.value ?? Date.now())}
              onChange={(d) => {
                d.setHours(d.getHours() + 12);
                if (d !== new Date(field.value)) form.setFieldValue('startAt', d);
              }}
              inputMode="start"
              mode="flat"
            />
          )}
        </Field>
        <ErrorMessage name="startAt" render={(message) => (<Text style={styles.error}>{message}</Text>)} />
      </FormControl>
      <FormControl style={styles.field}>
        <FormControl.Label>Date de fin</FormControl.Label>
        <Field
          id="endAt"
          name="endAt"
        >
          {({ field, form }) => (
            <DatePickerInput
              style={styles.whiteBackground}
              locale="fr-FR"
              label="Date de fin"
              value={new Date(field.value ?? Date.now())}
              onChange={(d) => {
                d.setHours(d.getHours() + 12);
                if (d !== new Date(field.value)) form.setFieldValue('endAt', d);
              }}
              inputMode="start"
              mode="flat"
            />
          )}
        </Field>
        <ErrorMessage name="endAt" render={(message) => (<Text style={styles.error}>{message}</Text>)} />
      </FormControl>
    </>
  );
}

function FormStepTwo() {
  const { values } = useWizard();
  function onMultiChange({ field, form, item }) {
    form.setFieldValue('categories', xorBy(field.value, [item], 'id'));
  }
  return (
    <>
      <FormControl style={styles.field}>
        <FormControl.Label>Type d'évènement</FormControl.Label>
        <Field id="level" name="level">
          {({ field, form }) => (
            <SelectBox
              label=""
              options={Object.entries(EVENT_LEVEL).map((e) => ({ id: e[0], item: e[1] }))}
              value={field.value}
              containerStyle={styles.whiteBackground}
              onChange={(value) => {
                form.setFieldValue('level', value);
                form.setFieldValue('registerEndAt', addDays(new Date(values.StepOne.startAt), value.item === EVENT_LEVEL.REGIONAL ? -15 : -30));
              }}
            />
          )}
        </Field>
        <ErrorMessage name="level" render={(message) => (<Text style={styles.error}>{message}</Text>)} />
      </FormControl>
      <FormControl style={styles.field}>
        <FormControl.Label>Date limite d'inscription</FormControl.Label>
        <Field
          id="registerEndAt"
          name="registerEndAt"
        >
          {({ field, form }) => (
            <DatePickerInput
              style={styles.whiteBackground}
              locale="fr-FR"
              label="Date limite d'inscription"
              value={new Date(field.value ?? Date.now())}
              onChange={(d) => {
                d.setHours(d.getHours() + 12);
                if (d !== new Date(field.value)) form.setFieldValue('registerEndAt', d);
              }}
              inputMode="start"
              mode="flat"
            />
          )}
        </Field>
        <ErrorMessage name="registerEndAt" render={(message) => (<Text style={styles.error}>{message}</Text>)} />
      </FormControl>
      <FormControl style={styles.field}>
        <FormControl.Label>Catégories</FormControl.Label>
        <Field
          id="categories"
          name="categories"
        >
          {({ field, form }) => (
            <SelectBox
              label=""
              containerStyle={styles.whiteBackground}
              options={Object.entries(AGE_CATEGORY).map((e) => ({ id: e[0], item: e[1].name }))}
              selectedValues={field.value}
              onMultiSelect={(item) => onMultiChange({ field, form, item })}
              onTapClose={(item) => onMultiChange({ field, form, item })}
              isMulti
            />
          )}
        </Field>
        <ErrorMessage name="categories" render={(message) => (<Text style={styles.error}>{message}</Text>)} />
      </FormControl>
      <FormControl style={styles.field}>
        <FormControl.Label>Media</FormControl.Label>
        <Field
          id="media"
          name="media"
        >
          {({ field, form }) => (
            <>
              <IconButton
                icon="image-edit"
                size={20}
                onPress={() => launchImageLibrary(
                  {
                    mediaType: 'photo',
                    maxWidth: '460px',
                    maxHeight: '200px',
                    includeBase64: true,
                  },
                  ({ didCancel, errorCode, assets }) => {
                    if (!didCancel && !errorCode && assets.length > 0) form.setFieldValue('media', assets[0].uri);
                  },
                )}
              />
              { field.value
              && (
              <Card.Cover
                style={{ alignItems: 'center', backgroundColor: 'transparent' }}
                source={{ uri: field.value, width: '460px', height: '200px' }}
              />
              )}
            </>
          )}
        </Field>
      </FormControl>

      <FormControl style={styles.field}>
        <FormControl.Label>Information supplémentaire</FormControl.Label>
        <Field
          id="infoMedia"
          name="infoMedia"
        >
          {({ form }) => (
            <IconButton
              icon="file-edit"
              size={20}
              onPress={async () => {
                const result = await getDocumentAsync({
                  type: ['application/pdf', 'image/jpeg'],
                });
                if (result.type === 'success') {
                  form.setFieldValue('infoMedia', result.uri);
                }
              }}
            />
          )}
        </Field>
      </FormControl>
    </>
  );
}

export { FormStepOne, FormStepTwo };
