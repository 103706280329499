import React from 'react-native';
import {
  NativeBaseProvider, ScrollView,
} from 'native-base';
import {
  Button, Dialog, IconButton, List, Portal, SegmentedButtons, Text,
} from 'react-native-paper';
import { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ModalContext } from '../../context/ModalContext';
import { FindOneAnnouncement } from '../../gql/announcement/query';

function UsersOnAnnouncement({ announcementId }) {
  const {
    displayUsersOnAnnouncementModal,
    toggleUsersOnAnnouncementModal,
  } = useContext(ModalContext);

  const { data, loading, error } = useQuery(
    FindOneAnnouncement,
    { variables: { findOneAnnouncementId: announcementId } },
  );

  const [filters, setFilters] = useState(['lu', 'nonlu']);

  return (
    <Dialog
      visible={displayUsersOnAnnouncementModal}
      dismissable
      onDismiss={toggleUsersOnAnnouncementModal}
    >
      <Dialog.Title>
        {`Statistique de l'annonce ${data?.findOneAnnouncement?.title}`}
      </Dialog.Title>
      <Dialog.ScrollArea style={{ maxHeight: '80vh' }}>
        <SegmentedButtons
          value={filters}
          multiSelect
          onValueChange={(e) => setFilters(e)}
          buttons={[
            {
              value: 'lu',
              label: 'Lu',
            },
            {
              value: 'nonlu',
              label: 'Non lu',
            },
          ]}
        />
        <ScrollView>
          {data?.findOneAnnouncement?.recipients?.filter((r) => filters.length === 2
            || (filters.length > 0 && filters[0] === 'lu' && r.hasBeenRead)
            || (filters.length > 0 && filters[0] === 'nonlu' && !r.hasBeenRead)).map((u) => (
              <List.Item
                key={`${u.user.firstName} ${u.user.lastName}`}
                title={`${u.user.firstName} ${u.user.lastName}`}
                right={() => (
                  <IconButton icon={u.hasBeenRead ? 'check' : 'progress-clock'} />
                )}
              />
          ))}
        </ScrollView>
      </Dialog.ScrollArea>
      <Dialog.Actions>
        <Button onPress={toggleUsersOnAnnouncementModal}>
          Fermer
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export default function UsersOnAnnouncementModal({ announcementId }) {
  return (
    <Portal>
      <NativeBaseProvider>
        <UsersOnAnnouncement announcementId={announcementId} />
      </NativeBaseProvider>
    </Portal>
  );
}
