import { NativeBaseProvider } from 'native-base';
import React, { useContext } from 'react';
import { Dialog, Portal } from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';
import { ModalContext } from '../../context/ModalContext';

function Filters() {
  const {
    displayFiltersModal,
    toggleFiltersModal,
  } = useContext(ModalContext);

  return (
    <Dialog
      visible={displayFiltersModal.display}
      dismissable
      onDismiss={toggleFiltersModal}
    >
      <Dialog.Title>
        Filtrer les evenements
      </Dialog.Title>
      <Dialog.ScrollArea style={{ maxHeight: '80vh' }}>
        {displayFiltersModal.filters?.map((filter) => (
          <Picker
            style={{ minHeight: 40 }}
            selectedValue={filter.value}
            onValueChange={(value) => filter.setValue(value)}
          >
            {filter.values
              .map((k) => <Picker.Item label={k.item} value={k.id} />)}
          </Picker>
        ))}
      </Dialog.ScrollArea>
    </Dialog>
  );
}

export default function ManageFiltersModal() {
  return (
    <Portal>
      <NativeBaseProvider>
        <Filters />
      </NativeBaseProvider>
    </Portal>
  );
}
